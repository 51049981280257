// Import all js dependencies.
import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min'

/*  Bootstrap Config
 *  You can import specifically the components you need from bootstrap
 *  by importing the node_modules/bootstrap/js/src/<component> needed.
 *  OR, you can import all of them by importing 'bootstrap/js/src/index'

   */

// import 'bootstrap/js/src/index' // Unlock all bootstrap utilities
// import 'bootstrap/js/dist/index' // Should unlock animations, but not finding jQuery for some reason

import 'bootstrap/js/dist/collapse'
